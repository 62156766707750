/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.MuiTableCell-head {
  color: #858585 !important;
  font-weight: 700 !important;
  font-size: 0.75rem !important;
  font-family: "Mulish" !important;
  border: 0 !important;
  padding-bottom: 0px !important;
}
.MuiTableRow-root td {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 5px;
  color: #686565;
  font-weight: 400;
  font-family: "Mulish";
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}
.MuiTableRow-root td:first-child {
  border-left: 1px solid #f0f0f0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.MuiTableRow-root td:last-child {
  border-right: 1px solid #f0f0f0;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.css-1auycx3-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 2px !important;
}
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 0.9rem !important;
  line-height: 1.165 !important;
  font-family: "Mulish" !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* select {
  -webkit-appearance: listbox !important;
} */

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

@media (min-width: 280px) and (max-width: 768px) {
  .ReactModal__Content {
    width: 80% !important;
    overflow-x: scroll;
  }
}
